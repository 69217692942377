<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ blogId ? 'Update': 'Add' }} news
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  What's new
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="blog.name"
                    :error="$v.blog.name.$error"
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.blog.name.$error">Name is required</span>
                <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="blog.publish_date"
                        :error="$v.blog.publish_date.$error"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    >
                      <template v-slot:label>
                        Publish date <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="blog.publish_date"
                      outlined
                      dense
                      no-title
                      @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.blog.publish_date.$error">This information is required</span>
                <span class="text-danger" v-if="errors.publish_date" >* {{ errors.publish_date[0] }}</span>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-select
                    item-text="name"
                    item-value="id"
                    v-model="blog.category_ids"
                    :items="blog_categories"
                    multiple
                    small-chips
                    deletable-chips
                    outlined
                    dense
                >
                  <template v-slot:label>
                    Category <span class="text-danger">*</span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="allSelectCategories">
                      <v-list-item-action>
                        <v-icon :color="blog.category_ids > 0 ? 'indigo darken-4' : ''">
                          {{ iconCategory }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select all
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                </v-select>
                <span class="text-danger" v-if="$v.blog.category_ids.$error">Category is required</span>
                <span class="text-danger" v-if="errors.category_ids" >* {{ errors.category_ids[0] }}</span>
              </v-col>

              <v-col cols="12" sm="12" md="6" v-if="currentUser.access_type!='score'">
                <v-select
                    outlined
                    dense item-text="name"
                    item-value="id"
                    :items="scores"
                    v-model="blog.score_id"
                    clearable
                >
                  <template v-slot:label>
                    SCORE
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Keywords"
                    v-model="blog.keywords"
                    outlined
                    dense
                >

                </v-text-field>
              </v-col>

              <v-col cols="12" v-bind:md="imgurl != null? 6 : 12">
                <v-file-input
                    label="Image"
                    v-model="blog.file"
                    @change="previewImage"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    outlined
                    dense
                >
                </v-file-input>
              </v-col>

              <v-col cols="12" md="6" v-if="imgurl != null">
                <div class="text-center">
                  <v-img
                      :src="imgurl"
                      :lazy-src="imgurl"
                      max-height="150"
                      max-width="150"
                      class="img-thumbnail"
                  ></v-img>
                </div>
              </v-col>

              <v-col cols="12" md="12">
                News content<span class="text-danger">*</span>
                <ckeditor
                    :config="editorConfig"
                    v-model="blog.description"
                >
                </ckeditor>
                <span class="text-danger" v-if="$v.blog.description.$error">This information is required</span>
                <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    label="SEO title"
                    v-model="blog.seo_title"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="SEO key words"
                    v-model="blog.seo_keyword"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                SEO description
                <ckeditor
                    :config="seoEditorConfig"
                    v-model="blog.seo_description"
                >
                </ckeditor>
              </v-col>

              <v-col cols="12" md="6">
                Social share description
                <ckeditor
                    :config="seoEditorConfig"
                    v-model="blog.social_share_description"
                >
                </ckeditor>
              </v-col>

              <v-col cols="12" md="6">
                Status
                <v-switch
                    v-model="blog.is_active"
                    :label="blog.is_active ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 text-right">
                <v-btn
                    @click="cancel"
                    class="mr-2 cancel-btn"
                    text
                    x-large
                >Cancel</v-btn>
                <v-btn
                    :loading="loading"
                    @click="createOrUpdate"
                    class="btn btn-primary"
                    dark
                    x-large
                >Save</v-btn>
              </v-col>

            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import BlogService from "@/services/cms/blog/BlogService";
import BlogCategoryService from "@/services/cms/blog-category/BlogCategoryService";
import { required } from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";

const blog = new BlogService();
const blogCategory = new BlogCategoryService();
const score = new ScoreService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      menu: false,
      loading: false,
      blog_categories: [],
      scores: [],
      errors: [],
      blogId: '',
      imgurl: null,
      currentUser: {},
      blog: {
        name: '',
        file: null,
        description: '',
        keywords: '',
        publish_date: '',
        seo_title: '',
        seo_keyword: '',
        seo_description: '',
        social_share_image: '',
        social_share_description: '',
        category_ids: [],
        score_id: '',
        is_active: true,
      },
      editorConfig: {
        toolbar: [
          { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'TextColor', 'BGColor', '-', 'Subscript', 'Superscript', '-', 'Font', 'FontSize','Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList'] },
          { name: 'styles', items: ['Styles', 'Format'] },
          { name: 'insert', items: ['Image', 'Table'] }
        ],
        versionCheck: false
      },
      seoEditorConfig:{
        toolbar: [
          { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'TextColor', 'BGColor', '-', 'Subscript', 'Superscript', '-', 'Font', 'FontSize','Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList'] },

        ],
        versionCheck: false
    }
    }
  },
  validations: {
    blog: {
      name: { required },
      publish_date: { required },
      description: { required },
      category_ids: { required },
      is_active: { required }
    }
  },
  mounted() {
    this.blogId = this.$route.params.blogId;
    this.blogDetail();
    this.getAllParent();
    this.getCurrentUser();
    this.getAllScore();
  },
  computed: {
    allSelectCategory () {
      return this.blog.category_ids.length === this.blog_categories.length
    },
    selectCategory () {
      return this.blog.category_ids.length > 0 && !this.allSelectCategory
    },
    iconCategory () {
      if (this.allSelectCategory) return 'mdi-close-box'
      if (this.selectCategory) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    getAllScore() {
      score.all().then(response => {
        this.scores = response.data.scores;
      }).catch(() => {});
    },
    allSelectCategories() {
      this.$nextTick(() => {
        if (this.allSelectCategory) {
          this.blog.category_ids = []
        } else {
          this.blog.category_ids = [];
          this.blog_categories.forEach(score => {
            this.blog.category_ids.push(score.id);
          });
        }
      })
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.blog) {
        if (key === "file" && this.blog[key] != null && this.blog[key] != undefined) {
          formData.append('file', this.blog[key]);
        }else {
          if(this.blog[key]){
            formData.append(key,this.blog[key]);
          }
        }
      }
      return formData;
    },
    blogDetail() {
      if (this.blogId != null || this.blogId != undefined) {
        blog
            .show(this.blogId)
            .then(response => {
              this.blog = response.data.blog;
              if(this.blog.image_path != null){
                this.imgurl = this.blog.image_path.thumb;
              }
            }).catch( err => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },
    previewImage(){
      if(this.blog.file != null){
        this.imgurl = URL.createObjectURL(this.blog.file)
      } else {
        this.imgurl = null;
      }
    },
    getAllParent() {
      blogCategory
          .getParent()
          .then(response => {
            this.blog_categories = response.data.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (this.blogId == null || this.blogId == undefined) this._create();
        else this.update();
      }
    },
    cancel() {
      this.$tabs.close();
    },
    _create: function() {
      let formData = this.convertToFormData();
      blog
          .create(formData)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("Whats new added");
            this.resetForm();
            this.$tabs.close().then(response=>{
              this.$tabs.open({name:'blog'});
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      let formData = this.convertToFormData();

      blog
          .update(this.blog.id, formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Whats new updated");
            this.resetForm();
            this.$tabs.close().then(response=>{
              this.$tabs.open({name:'blog'});
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.blog = {
        name: '',
        file: null,
        description: '',
        keywords: '',
        score_id: '',
        publish_date: '',
        seo_title: '',
        seo_keyword: '',
        seo_description: '',
        social_share_image: '',
        social_share_description: '',
        category_ids: [],
        is_active: true,
      }
    },
  }
}
</script>